import SaveUrl from "./components/save-url/SaveUrl";

function App() {
  return (
    <div>
      <SaveUrl />
    </div>
  );
}

export default App;
